<template>
  <v-row class="lasa-questionnaire-panel reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(answerGroup, key) in questionnaire.answers"
          :key="key"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == key ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ key }}
            </span>
            <span class="float-right flex-2 score">
              {{ $t("score") }} <strong>{{ answerGroup.score.score }}</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <template v-for="(answers, questionType) in answerGroup.data">
              <v-simple-table
                class="mt-8"
                v-if="questionType == 'scale_single_color'"
                :key="questionType"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th colspan="5" class="mcq-indicator">
                        {{
                          optionsWithLocales(answers)[0].locales[0].option_text
                        }}
                      </th>
                      <th class="mcq-indicator"></th>
                      <th colspan="5" class="mcq-indicator">
                        {{
                          optionsWithLocales(answers)[1].locales[0].option_text
                        }}
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th
                        v-for="(option, optionIndex) in answers[0].question
                          .options"
                        :key="optionIndex"
                        class="text-center text-uppercase font-weight-bold"
                      >
                        {{ optionIndex }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(answer, answerIndex) in answers"
                      :key="answerIndex"
                    >
                      <td>
                        <span class="mr-2">{{ answerIndex + 1 }}.</span>
                        <span>
                          {{ answer.question.locales[0].question_text }}
                        </span>
                      </td>
                      <td
                        v-for="availaleOption in answer.question.options"
                        :key="availaleOption.id"
                      >
                        <div
                          class="circle rounded-circle"
                          :class="{
                            selected: availaleOption.id == answer.option.id,
                          }"
                        >
                          <v-icon v-if="availaleOption.id == answer.option.id">
                            icon-tick
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LASAQuestionnairePanels",

  props: {
    questionnaire: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },

  computed: {
    optionsWithLocales() {
      return (answers) =>
        answers[0].question.options.filter(
          (option) => option.locales.length > 0
        );
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/questionnaires/components/lasa-questionnaire-panels.scss">
</style>